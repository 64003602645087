import React from 'react'
import { PageProps, Link } from 'gatsby'
import { useApi } from '~/siteApi'
import { useContext } from '~/hooks'
import { Head, Footer } from '~/components'
import * as st from '~/assets/styl/PageDefault.module.styl'
import * as sc from '~/assets/styl/PrincipaisEntregaveis.module.styl'

interface PageContext {
  department: {
    name: string
    slug: string
  }
  deliverables: [
    {
      name: string
      slug: string
      color: string
    }
  ]
}

const PrincipaisEntregaveis = ({
  location,
  pageContext = {},
  '*': slug,
}: PageProps & { '*'?: string }) => {
  const { setLoading } = useContext()
  const context = useApi(
    pageContext,
    `page-deliverable/${(pageContext as PageContext).department?.slug || slug}`
  ) as PageContext
  setLoading(!context.department)
  return context.department ? (
    <>
      <Head location={location} />
      <main className={st.core}>
        <section className="container">
          <h1>
            Principais entregáveis{' '}
            <span className="block">{context.department.name}</span>
          </h1>
          <ul className={sc.list}>
            {context.deliverables.map(({ name, slug, color }, index) => (
              <li key={index} style={{ color: `${color}` }}>
                <p>
                  <span>Saiba mais</span>
                </p>
                <Link to={`/${context.department.slug}/${slug}`}>{name}</Link>
              </li>
            ))}
          </ul>
        </section>
      </main>
      <Footer location={location} />
    </>
  ) : (
    <></>
  )
}

export default PrincipaisEntregaveis
